<template>
  <div class="profile" style="padding: 0">
    <a-form :form="form" @submit="onFormSubmit" v-if="formLoading">
      <a-row class="user-profile-page">
        <a-col
          class="banner"
          :style="
            userData.meta.cover
              ? `background: #ddd  url(${userData.meta.cover}) no-repeat center`
              : ''
          "
          :span="24"
        >
        </a-col>
        <a-col :span="16" :offset="4">
          <a-row>
            <a-col class="sidebar" :span="6">
              <div class="avatar">
                <a-form-item class="travel-avatar-wrapper mb-20">
                  <div class="image-uploader-container">
                    <a-avatar
                      v-if="theAvatar"
                      :src="theAvatar"
                      class="upper"
                      style="
                        color: #be4178;
                        background-color: #ffe3ef;
                        border: 10px solid #fff;
                      "
                      :size="160"
                    >
                    </a-avatar>
                    <a-upload
                      v-else
                      class="avatar-uploader travel-avatar"
                      name="file"
                      :multiple="false"
                      :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=users/avatar&id=${$store.state.userData.id}&sub-folder=`"
                      :remove="removeUploadedFile"
                      :headers="{
                        authorization: `Bearer ${this.$store.state.token}`,
                      }"
                      @change="uploadedFile"
                      v-model="theAvatar"
                    >
                      <a-button
                        type="dashed"
                        shape="circle"
                        size="large"
                        style="width: 160px; height: 160px; font-size: 40px"
                      >
                        <a-icon type="user" />
                      </a-button>
                    </a-upload>
                  </div>

                  <a-button
                    v-if="theAvatar"
                    type="danger"
                    class="remove"
                    shape="circle"
                    @click="removeAvatar()"
                    style="
                      top: 0;
                      position: absolute;
                      background: #fff;
                      color: red;
                    "
                  >
                    <a-icon type="delete" />
                  </a-button>
                </a-form-item>

                <div class="name item">
                  {{ this.form.getFieldValue("first_name") }}
                  {{ this.form.getFieldValue("last_name") }}
                </div>
                <div v-show="form.getFieldValue('phone')" class="phone item">
                  <i class="fas fa-phone-alt"></i>
                  {{ this.form.getFieldValue("phone") }}
                </div>

                <div
                  v-show="form.getFieldValue('mobile_phone')"
                  class="mobile item"
                >
                  <i class="fas fa-mobile-alt"></i>
                  {{ this.form.getFieldValue("mobile_phone") }}
                </div>

                <div
                  v-show="form.getFieldValue('birthday')"
                  class="birthday item"
                >
                  <i class="fas fa-birthday-cake"></i>
                  {{ formatMultiDates(this.form.getFieldValue("birthday")) }}
                </div>
              </div>
            </a-col>
            <a-col class="content" :span="18">
              <a-row>
                <a-col class="menu" :span="24">
                  <a-button type="link"> Meus Dados </a-button>
                  <a-divider type="vertical" />
                  <a-button type="link" :disabled="true">
                    Minhas Vendas
                  </a-button>
                  <a-divider type="vertical" />
                  <a-button type="link" :disabled="true"> Histórico </a-button>
                </a-col>
                <a-col class="tab-content" :span="24">
                  <a-row
                    :gutter="[20, 0]"
                    align="middle"
                    justify="center"
                    type="flex"
                  >
                    <a-col :span="12">
                      <a-form-item class="travel-input">
                        <label
                          :class="haveTheField('first_name') ? 'filled' : ''"
                          >Primeiro nome</label
                        >
                        <a-input
                          size="default"
                          v-decorator="[
                            'first_name',
                            {
                              initialValue: haveTheField('first_name'),
                              rules: [
                                {
                                  required: true,
                                  message: 'Obrigatório',
                                },
                              ],
                            },
                          ]"
                          placeholder="Primeiro nome"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>

                    <a-col :span="12">
                      <a-form-item class="travel-input">
                        <label
                          :class="haveTheField('last_name') ? 'filled' : ''"
                          >Sobrenome</label
                        >
                        <a-input
                          size="default"
                          v-decorator="[
                            'last_name',
                            {
                              initialValue: haveTheField('last_name'),
                              rules: [
                                {
                                  required: true,
                                  message: 'Obrigatório',
                                },
                              ],
                            },
                          ]"
                          placeholder="Sobrenome"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>

                  <a-row
                    :gutter="[20, 0]"
                    align="middle"
                    justify="center"
                    type="flex"
                  >
                    <a-col :span="12">
                      <a-form-item class="travel-input">
                        <label :class="haveTheField('email') ? 'filled' : ''"
                          >E-mail</label
                        >
                        <a-input
                          autoComplete="off"
                          size="default"
                          v-decorator="[
                            'email',
                            {
                              initialValue: haveTheField('email'),
                              rules: [
                                {
                                  type: 'email',
                                  message: 'Escreva um e-mail válido',
                                },
                                {
                                  required: true,
                                  message: 'Obrigatório',
                                },
                              ],
                            },
                          ]"
                          placeholder="E-mail"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>

                    <a-col :span="12">
                      <a-form-item class="travel-input">
                        <label
                          :class="
                            this.form.getFieldValue('birthday') ? 'filled' : ''
                          "
                          >Nascimento</label
                        >
                        <a-date-picker
                          placeholder="Nascimento"
                          format="DD/MM/YYYY"
                          v-mask="'##/##/####'"
                          :showToday="false"
                          @openChange="defaultDate"
                          v-decorator="[
                            'birthday',
                            {
                              rules: [
                                {
                                  required: false,
                                  message: 'Obrigatório',
                                },
                              ],
                            },
                          ]"
                        >
                        </a-date-picker>
                      </a-form-item>
                    </a-col>

                    <a-col :span="12">
                      <a-form-item class="travel-input">
                        <label
                          :class="
                            this.form.getFieldValue('mobile_phone')
                              ? 'filled'
                              : ''
                          "
                          >Celular</label
                        >
                        <a-input
                          v-mask="'(##) # ####-####'"
                          v-decorator="[
                            'mobile_phone',
                            {
                              rules: [
                                {
                                  required: false,
                                  message: 'Obrigatório',
                                },
                              ],
                            },
                          ]"
                          placeholder="Celular"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>

                    <a-col :span="12">
                      <a-form-item class="travel-input">
                        <label
                          :class="
                            this.form.getFieldValue('phone') ? 'filled' : ''
                          "
                          >Telefone Fixo/Ramal</label
                        >
                        <a-input
                          v-mask="'(##) ####-####'"
                          v-decorator="[
                            'phone',
                            {
                              rules: [
                                {
                                  required: false,
                                  message: 'Obrigatório',
                                },
                              ],
                            },
                          ]"
                          placeholder="Telefone Fixo/Ramal"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>

                    <a-col v-if="$root.isAdmin()" :span="12"> </a-col>
                    <a-col v-else :span="12"> </a-col>
                  </a-row>

                  <a-row
                    :gutter="[20, 0]"
                    align="middle"
                    justify="center"
                    type="flex"
                  >
                    <a-col :span="12">
                      <a-form-item class="travel-input">
                        <label
                          :class="
                            this.form.getFieldValue('userPassword')
                              ? 'filled'
                              : ''
                          "
                          >Senha</label
                        >
                        <a-input
                          class="travel-input"
                          size="default"
                          v-decorator="[
                            'userPassword',
                            {
                              rules: [
                                {
                                  required: false,
                                  message: 'Escreva sua nova senha.',
                                },
                              ],
                            },
                          ]"
                          type="password"
                          placeholder="Insira a senha"
                        >
                        </a-input></a-form-item
                    ></a-col>
                    <a-col :span="12">
                      <a-form-item class="travel-input">
                        <label
                          :class="
                            this.form.getFieldValue('password') ? 'filled' : ''
                          "
                          >Repita a senha</label
                        >
                        <a-input
                          class="travel-input"
                          size="default"
                          v-decorator="[
                            'password',
                            {
                              rules: [
                                {
                                  required: false,
                                  message: 'Repita a  senha.',
                                },
                                {
                                  validator: compareToFirstPassword,
                                },
                              ],
                            },
                          ]"
                          type="password"
                          placeholder="Repita a senha"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>

                  <a-form-item class="a-right mt-20">
                    <a-button
                      :loading="userUpdateLoading"
                      type="primary"
                      size="large"
                      html-type="submit"
                    >
                      Atualizar meu perfil
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-form>
    <div v-else style="padding: 24px">
      <a-skeleton /> <a-skeleton /> <a-skeleton />
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default {
  name: "Profile",
  mixins: [formatThings],
  data() {
    return {
      editUser: true,
      userData: {},
      form: this.$form.createForm(this),
      loading: false,
      imageUrl: "",
      theAvatar: null,
      imageBase64: null,
      userUpdateLoading: false,
      formLoading: false,
    };
  },
  beforeMount() {
    this.getUser();
  },
  methods: {
    removeUploadedFile() {
      this.theAvatar = "";
    },
    uploadedFile(response) {
      console.log("uploadedFile", response);
      if (response.file.response.url != undefined) {
        this.theAvatar = response.file.response.url;
      }
    },
    onFormSubmit(e) {
      e.preventDefault();
      this.userUpdateLoading = true;

      this.form.validateFields((err, values) => {
        values.avatar = this.theAvatar;
        console.log("VALORES:", err, values);
        if (!err) {
          if (values.userPassword && values.password) {
            values.password;
          } else {
            values.password = "";
          }

          values.id = this.userData.id;
          values.status = this.userData.status;
          values.role = this.userData.role;

          this.updateUser(values);
        }
      });
    },
    getUser() {
      this.$http
        .post("/user/read_one", {
          id: this.$store.state.userData.id,
        })
        .then(({ data }) => {
          this.userData = data;

          setTimeout(() => {
            this.form.setFieldsValue({
              birthday: data.meta.birthday,
              phone: data.meta.phone,
              mobile_phone: data.meta.mobile_phone,
            });
          }, 200);

          this.theAvatar = data.meta.avatar;
          this.formLoading = true;
        })
        .finally(() => {});
    },
    updateUser(values) {
      if (!this.$root.isAdmin()) {
        values.role = this.userData.role;
        values.status = this.userData.status;
      }

      this.$http
        .post("/user/update", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.userUpdateLoading = false;
          this.userData = data.userData;

          let localUserData = {};

          localUserData.email = data.userData.email;
          localUserData.first_name = data.userData.first_name;
          localUserData.last_name = data.userData.last_name;
          localUserData.id = data.userData.id;
          localUserData.role = data.userData.role;
          localUserData.meta = { avatar: data.userData.meta.avatar };

          console.log("local", JSON.stringify(localUserData));

          localStorage.setItem("userData", JSON.stringify(localUserData));

          setTimeout(() => {
            location.reload();
          }, 20);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.userUpdateLoading = false;
        });
    },
    onStatusChange(checked) {
      checked;
    },
    selectRole(value) {
      value;
    },
    compareToFirstPassword(rule, value, callback) {
      rule;
      const form = this.form;
      if (value && value !== form.getFieldValue("userPassword")) {
        callback("As senhas digitadas não são iguais.");
      } else {
        callback();
      }
    },
    removeAvatar() {
      this.userData.meta.avatar = "";
      this.form.setFieldsValue({
        avatar: "",
      });
      this.theAvatar = "";
    },
    handleChange(info) {
      if (this.isValidFile) {
        getBase64(info.file.originFileObj, (imageBase64) => {
          this.imageBase64 = imageBase64;
          this.theAvatar = imageBase64;
          console.log("aquio", imageBase64);

          setTimeout(() => {
            this.form.setFieldsValue({
              avatar: imageBase64,
            });
          }, 1);
        });
      }
    },
    beforeUpload(file) {
      const isValidImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isValidImage) {
        this.$message.error("Este arquivo não é válido!");
        return;
      }
      const validSize = file.size / 1024 / 1024 < 2;
      if (!validSize) {
        this.$message.error("A imagem deve ter menos que 2MB!");
        return;
      }
      this.isValidFile = true;
      return isValidImage && validSize;
    },
    haveTheField(field) {
      if (field == "avatar") {
        return this.form.getFieldValue(field) || this.userData.meta[field];
      } else {
        return this.form.getFieldValue(field) || this.userData[field];
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.banner
  background-size: cover !important
</style>
